"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const MilestoneAwardManifest_1 = require("@/client/MilestoneAwardManifest");
exports.default = vue_1.default.extend({
    name: 'Milestone',
    props: {
        milestone: {
            type: Object,
        },
        showScores: {
            type: Boolean,
            default: true,
        },
        showDescription: {
            type: Boolean,
        },
    },
    computed: {
        nameCss() {
            return 'ma-name ma-name--' + this.milestone.name.replace(/ /g, '-').replace(/\./g, '').toLowerCase();
        },
        sortedScores() {
            return [...this.milestone.scores].sort((s1, s2) => s2.playerScore - s1.playerScore);
        },
        description() {
            return (0, MilestoneAwardManifest_1.getMilestone)(this.milestone.name).description;
        },
    },
});
