"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.playerIndex = void 0;
const vue_1 = require("vue");
const PlayerInfo_vue_1 = require("@/client/components/overview/PlayerInfo.vue");
const OverviewSettings_vue_1 = require("@/client/components/overview/OverviewSettings.vue");
const OtherPlayer_vue_1 = require("@/client/components/OtherPlayer.vue");
const Phase_1 = require("@/common/Phase");
const SHOW_NEXT_LABEL_MIN = 2;
const playerIndex = (color, players) => {
    for (let idx = 0; idx < players.length; idx++) {
        if (players[idx].color === color) {
            return idx;
        }
    }
    return -1;
};
exports.playerIndex = playerIndex;
exports.default = vue_1.default.extend({
    name: 'PlayersOverview',
    props: {
        playerView: {
            type: Object,
        },
    },
    computed: {
        players() {
            return this.playerView.players;
        },
        thisPlayer() {
            return this.playerView.thisPlayer;
        },
    },
    components: {
        'player-info': PlayerInfo_vue_1.default,
        'overview-settings': OverviewSettings_vue_1.default,
        'other-player': OtherPlayer_vue_1.default,
    },
    data() {
        return {};
    },
    methods: {
        hasPlayers() {
            return this.players.length > 0;
        },
        getIsFirstForGen(player) {
            return (0, exports.playerIndex)(player.color, this.players) === 0;
        },
        getPlayersInOrder() {
            const players = this.players;
            if (this.thisPlayer === undefined) {
                return players;
            }
            let result = [];
            let currentPlayerOffset = 0;
            const currentPlayerIndex = (0, exports.playerIndex)(this.thisPlayer.color, this.players);
            currentPlayerOffset = currentPlayerIndex + 1;
            result = players
                .slice(currentPlayerOffset)
                .concat(players.slice(0, currentPlayerOffset));
            return result.slice(0, -1);
        },
        getActionLabel(player) {
            if (this.playerView.game.phase === Phase_1.Phase.DRAFTING) {
                if (player.needsToDraft) {
                    return 'drafting';
                }
                else {
                    return 'none';
                }
            }
            else if (this.playerView.game.phase === Phase_1.Phase.RESEARCH) {
                if (player.needsToResearch) {
                    return 'researching';
                }
                else {
                    return 'none';
                }
            }
            if (this.playerView.game.passedPlayers.includes(player.color)) {
                return 'passed';
            }
            if (player.isActive)
                return 'active';
            const notPassedPlayers = this.players.filter((p) => !this.playerView.game.passedPlayers.includes(p.color));
            const currentPlayerIndex = (0, exports.playerIndex)(player.color, notPassedPlayers);
            if (currentPlayerIndex === -1) {
                return 'none';
            }
            const prevPlayerIndex = currentPlayerIndex === 0 ?
                notPassedPlayers.length - 1 :
                currentPlayerIndex - 1;
            const isNext = notPassedPlayers[prevPlayerIndex].isActive;
            if (isNext && this.players.length > SHOW_NEXT_LABEL_MIN) {
                return 'next';
            }
            return 'none';
        },
    },
});
