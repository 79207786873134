"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MACompatibility = void 0;
const BoardName_1 = require("../boards/BoardName");
exports.MACompatibility = {
    'Landlord': { map: BoardName_1.BoardName.THARSIS },
    'Scientist': { map: BoardName_1.BoardName.THARSIS },
    'Banker': { map: BoardName_1.BoardName.THARSIS },
    'Thermalist': { map: BoardName_1.BoardName.THARSIS },
    'Miner': { map: BoardName_1.BoardName.THARSIS },
    'Celebrity': { map: BoardName_1.BoardName.ELYSIUM },
    'Industrialist': { map: BoardName_1.BoardName.ELYSIUM },
    'Desert Settler': { map: BoardName_1.BoardName.ELYSIUM },
    'Estate Dealer': { map: BoardName_1.BoardName.ELYSIUM },
    'Benefactor': { map: BoardName_1.BoardName.ELYSIUM },
    'Cultivator': { map: BoardName_1.BoardName.HELLAS },
    'Excentric': { map: BoardName_1.BoardName.HELLAS },
    'Magnate': { map: BoardName_1.BoardName.HELLAS },
    'Space Baron': { map: BoardName_1.BoardName.HELLAS },
    'Contractor': { map: BoardName_1.BoardName.HELLAS },
    'Venuphile': { compatibility: 'venus' },
    'Entrepreneur': { compatibility: 'ares' },
    'Full Moon': { compatibility: 'moon' },
    'Lunar Magnate': { compatibility: 'moon' },
    'Curator': { map: BoardName_1.BoardName.AMAZONIS },
    'Engineer': { map: BoardName_1.BoardName.AMAZONIS },
    'Coordinator': { map: [BoardName_1.BoardName.AMAZONIS, BoardName_1.BoardName.ARABIA_TERRA] },
    'Tourist': { map: BoardName_1.BoardName.AMAZONIS },
    'A. Zoologist': { map: BoardName_1.BoardName.AMAZONIS },
    'Cosmic Settler': { map: BoardName_1.BoardName.ARABIA_TERRA },
    'Botanist': { map: BoardName_1.BoardName.ARABIA_TERRA },
    'Zoologist': { map: BoardName_1.BoardName.ARABIA_TERRA },
    'Manufacturer': { map: BoardName_1.BoardName.ARABIA_TERRA },
    'Biologist': { map: BoardName_1.BoardName.TERRA_CIMMERIA },
    'T. Economizer': { map: BoardName_1.BoardName.TERRA_CIMMERIA },
    'Politician': { map: BoardName_1.BoardName.TERRA_CIMMERIA },
    'Urbanist': { map: BoardName_1.BoardName.TERRA_CIMMERIA },
    'Warmonger': { map: BoardName_1.BoardName.TERRA_CIMMERIA },
    'Adapter': { map: BoardName_1.BoardName.VASTITAS_BOREALIS },
    'Edgedancer': { map: BoardName_1.BoardName.VASTITAS_BOREALIS },
    'Hoarder': { map: BoardName_1.BoardName.VASTITAS_BOREALIS },
    'Naturalist': { map: BoardName_1.BoardName.VASTITAS_BOREALIS },
    'Voyager': { map: BoardName_1.BoardName.VASTITAS_BOREALIS },
    'Kingpin': { compatibility: 'underworld' },
    'EdgeLord': { compatibility: 'underworld' },
    'Terraformer': { map: BoardName_1.BoardName.THARSIS },
    'Mayor': { map: BoardName_1.BoardName.THARSIS },
    'Gardener': { map: BoardName_1.BoardName.THARSIS },
    'Planner': { map: BoardName_1.BoardName.THARSIS },
    'Builder': { map: BoardName_1.BoardName.THARSIS },
    'Generalist': { map: BoardName_1.BoardName.ELYSIUM },
    'Specialist': { map: BoardName_1.BoardName.ELYSIUM },
    'Ecologist': { map: BoardName_1.BoardName.ELYSIUM },
    'Tycoon': { map: BoardName_1.BoardName.ELYSIUM },
    'Legend': { map: BoardName_1.BoardName.ELYSIUM },
    'Diversifier': { map: BoardName_1.BoardName.HELLAS },
    'Tactician': { map: BoardName_1.BoardName.HELLAS },
    'Polar Explorer': { map: BoardName_1.BoardName.HELLAS },
    'Energizer': { map: BoardName_1.BoardName.HELLAS },
    'Rim Settler': { map: BoardName_1.BoardName.HELLAS },
    'Hoverlord': { compatibility: 'venus' },
    'Networker': { compatibility: 'ares' },
    'One Giant Step': { compatibility: 'moon' },
    'Lunarchitect': { compatibility: 'moon' },
    'Colonizer': { map: BoardName_1.BoardName.AMAZONIS },
    'Farmer': { map: BoardName_1.BoardName.AMAZONIS },
    'Minimalist': { map: BoardName_1.BoardName.AMAZONIS },
    'Terran': { map: BoardName_1.BoardName.AMAZONIS },
    'Tropicalist': { map: BoardName_1.BoardName.AMAZONIS },
    'Economizer': { map: BoardName_1.BoardName.ARABIA_TERRA },
    'Pioneer': { map: BoardName_1.BoardName.ARABIA_TERRA },
    'Land Specialist': { map: BoardName_1.BoardName.ARABIA_TERRA },
    'Martian': { map: BoardName_1.BoardName.ARABIA_TERRA },
    'Businessperson': { map: BoardName_1.BoardName.ARABIA_TERRA },
    'Collector': { map: BoardName_1.BoardName.TERRA_CIMMERIA },
    'Firestarter': { map: BoardName_1.BoardName.TERRA_CIMMERIA },
    'Terra Pioneer': { map: BoardName_1.BoardName.TERRA_CIMMERIA },
    'Spacefarer': { map: BoardName_1.BoardName.TERRA_CIMMERIA },
    'Gambler': { map: BoardName_1.BoardName.TERRA_CIMMERIA },
    'Electrician': { map: BoardName_1.BoardName.VASTITAS_BOREALIS },
    'Smith': { map: BoardName_1.BoardName.VASTITAS_BOREALIS },
    'Tradesman': { map: BoardName_1.BoardName.VASTITAS_BOREALIS },
    'Irrigator': { map: BoardName_1.BoardName.VASTITAS_BOREALIS },
    'Capitalist': { map: BoardName_1.BoardName.VASTITAS_BOREALIS },
    'Tunneler': { compatibility: 'underworld' },
    'Risktaker': { compatibility: 'underworld' },
};
