"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpaceName = void 0;
exports.SpaceName = {
    ARSIA_MONS: '29',
    ASCRAEUS_MONS: '14',
    GANYMEDE_COLONY: '01',
    NOCTIS_CITY: '31',
    PAVONIS_MONS: '21',
    PHOBOS_SPACE_HAVEN: '02',
    THARSIS_THOLUS: '09',
    LUNA_METROPOLIS: '70',
    DAWN_CITY: '71',
    STRATOPOLIS: '72',
    MAXWELL_BASE: '73',
    ARSIA_MONS_ELYSIUM: '37',
    HECATES_THOLUS: '08',
    ELYSIUM_MONS: '14',
    OLYMPUS_MONS: '20',
    HELLAS_OCEAN_TILE: '61',
    STANFORD_TORUS: '69',
    VASTITAS_BOREALIS_NOVUS_HECATES_THOLUS: '05',
    VASTITAS_BOREALIS_NOVUS_ELYSIUM_MONS: '13',
    VASTITAS_BOREALIS_NOVUS_ALBA_MONS: '21',
    VASTITAS_BOREALIS_NOVUS_URANIUS_THOULS: '46',
    VASTITAS_BOREALIS_NOVUS_NORTH_POLE: '33',
    VASTITAS_BOREALIS_NORTH_POLE: '33',
    ELYSIUM_MONS_VASTITAS_BOREALIS: '07',
    ALBA_FOSSAE: '12',
    CERANIUS_FOSSAE: '21',
    ALBA_MONS: '22',
    TIKHONAROV: '20',
    LADON: '55',
    FLAUGERGUES: '58',
    CHARYBDIS: '63',
    CERES_SPACEPORT: '75',
    DYSON_SCREENS: '76',
    LUNAR_EMBASSY: '77',
    VENERA_BASE: '78',
    MEDUSAE_FOSSAE: '33',
    ALBOR_THOLUS: '09',
    ANSERIS_MONS: '35',
    PINDUS_MONS: '43',
    ULYSSES_THOLUS: '49',
    ALBOR_THOLUS_TERRACIMMERIA: '05',
    APOLLINARIS_MONS: '27',
    HADRIACUS_MONS: '38',
    TYRRHENUS_MONS: '21',
};
