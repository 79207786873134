"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardListSearchIndex = void 0;
const ClientGlobalEventManifest_1 = require("@/client/turmoil/ClientGlobalEventManifest");
const ClientCardManifest_1 = require("@/client/cards/ClientCardManifest");
const ClientColonyManifest_1 = require("@/client/colonies/ClientColonyManifest");
const ICardRenderDescription_1 = require("@/common/cards/render/ICardRenderDescription");
const Types_1 = require("@/common/cards/render/Types");
const CardRenderItemType_1 = require("@/common/cards/render/CardRenderItemType");
const i18n_1 = require("@/client/directives/i18n");
const MilestoneAwardManifest_1 = require("../../MilestoneAwardManifest");
const utils_1 = require("@/common/utils/utils");
const AwardName_1 = require("@/common/ma/AwardName");
const MilestoneName_1 = require("@/common/ma/MilestoneName");
class CardListSearchIndex {
    constructor() {
        this.searchIndex = new Map();
        this.entries = [];
    }
    build() {
        for (const card of (0, ClientCardManifest_1.getCards)(() => true)) {
            this.add(card.name);
            const metadata = card.metadata;
            const description = metadata.description;
            if (description !== undefined) {
                const text = (0, ICardRenderDescription_1.isIDescription)(description) ? description.text : description;
                this.add(text);
            }
            if (metadata.renderData) {
                this.process(metadata.renderData);
            }
            this.store('card', card.name);
        }
        for (const colonyName of (0, ClientColonyManifest_1.allColonyNames)()) {
            this.add(colonyName);
            this.store('colony', colonyName);
        }
        for (const globalEventName of (0, ClientGlobalEventManifest_1.allGlobalEventNames)()) {
            const globalEvent = (0, ClientGlobalEventManifest_1.getGlobalEventOrThrow)(globalEventName);
            this.add(globalEvent.name);
            this.add(globalEvent.description);
            this.process(globalEvent.renderData);
            this.store('globalEvent', globalEvent.name);
        }
        for (const milestoneName of MilestoneName_1.milestoneNames) {
            this.add(milestoneName);
            this.add((0, MilestoneAwardManifest_1.getMilestone)(milestoneName).description);
            this.store('ma', milestoneName);
        }
        for (const awardName of AwardName_1.awardNames) {
            this.add(awardName);
            this.add((0, MilestoneAwardManifest_1.getAward)(awardName).description);
            this.store('ma', awardName);
        }
    }
    matches(text, type, name) {
        const normalized = this.normalize(text);
        const entries = this.searchIndex.get(`${type}:${name}`);
        return entries?.some((entry) => entry.includes(normalized)) ?? false;
    }
    process(component) {
        if ((0, Types_1.isICardRenderItem)(component)) {
            if (component.type === CardRenderItemType_1.CardRenderItemType.TEXT && component.text !== undefined) {
                this.add(component.text);
            }
        }
        else if ((0, Types_1.isICardRenderRoot)(component) ||
            (0, Types_1.isICardRenderCorpBoxEffect)(component) ||
            (0, Types_1.isICardRenderCorpBoxAction)(component) ||
            (0, Types_1.isICardRenderEffect)(component) ||
            (0, Types_1.isICardRenderProductionBox)(component)) {
            component.rows.forEach((row) => {
                row.forEach((item) => {
                    if (typeof (item) === 'string') {
                        this.add(item);
                    }
                    else if (item !== undefined) {
                        this.process(item);
                    }
                });
            });
        }
    }
    add(text) {
        this.entries.push(this.normalize(text));
    }
    normalize(text) {
        return (0, i18n_1.translateText)(text).toLocaleUpperCase();
    }
    store(type, name) {
        this.searchIndex.set(`${type}:${name}`, (0, utils_1.copyAndClear)(this.entries));
    }
}
exports.CardListSearchIndex = CardListSearchIndex;
