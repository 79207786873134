"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const AppButton_vue_1 = require("@/client/components/common/AppButton.vue");
const ClientCardManifest_1 = require("@/client/cards/ClientCardManifest");
const CardType_1 = require("@/common/cards/CardType");
const allItems = [
    ...(0, ClientCardManifest_1.getCards)((0, ClientCardManifest_1.byType)(CardType_1.CardType.AUTOMATED)),
    ...(0, ClientCardManifest_1.getCards)((0, ClientCardManifest_1.byType)(CardType_1.CardType.ACTIVE)),
    ...(0, ClientCardManifest_1.getCards)((0, ClientCardManifest_1.byType)(CardType_1.CardType.EVENT)),
    ...(0, ClientCardManifest_1.getCards)((0, ClientCardManifest_1.byType)(CardType_1.CardType.CEO)),
].map(ClientCardManifest_1.toName)
    .sort((a, b) => a.localeCompare(b));
exports.default = vue_1.default.extend({
    name: 'CardsFilter',
    props: {
        title: {
            type: String,
            required: true,
        },
        hint: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            selectedCardNames: [],
            foundCardNames: [],
            searchTerm: '',
        };
    },
    components: {
        AppButton: AppButton_vue_1.default,
    },
    methods: {
        isPrelude(cardName) {
            return (0, ClientCardManifest_1.getCard)(cardName)?.type === CardType_1.CardType.PRELUDE;
        },
        isCEO(cardName) {
            return (0, ClientCardManifest_1.getCard)(cardName)?.type === CardType_1.CardType.CEO;
        },
        removeCard(cardNameToRemove) {
            this.selectedCardNames = this.selectedCardNames.filter((curCardName) => curCardName !== cardNameToRemove).sort();
        },
        addCard(cardNameToAdd) {
            if (this.selectedCardNames.includes(cardNameToAdd))
                return;
            this.selectedCardNames.push(cardNameToAdd);
            this.selectedCardNames.sort();
            this.searchTerm = '';
        },
    },
    watch: {
        selectedCardNames(value) {
            this.$emit('cards-list-changed', value);
        },
        searchTerm(value) {
            if (value === '') {
                this.foundCardNames = [];
                return;
            }
            if (value.indexOf(',') !== -1) {
                const cardNames = new Set(value.split(',').map((c) => c.trim()));
                for (const item of allItems) {
                    if (cardNames.has(item)) {
                        this.addCard(item);
                    }
                }
                return;
            }
            const newCardNames = allItems.filter((candidate) => !this.selectedCardNames.includes(candidate) && candidate.toLowerCase().indexOf(value.toLowerCase()) !== -1).sort();
            this.foundCardNames = newCardNames.slice(0, 5);
        },
    },
});
