"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const paths_1 = require("@/common/app/paths");
const statusCode_1 = require("@/common/http/statusCode");
const utils_1 = require("@/common/utils/utils");
const SoundManager_1 = require("@/client/utils/SoundManager");
const PreferencesManager_1 = require("@/client/utils/PreferencesManager");
const LogMessageComponent_vue_1 = require("@/client/components/logpanel/LogMessageComponent.vue");
const CardPanel_vue_1 = require("@/client/components/logpanel/CardPanel.vue");
let logRequest;
exports.default = vue_1.default.extend({
    name: 'log-panel',
    props: {
        id: {
            type: String,
        },
        generation: {
            type: Number,
        },
        lastSoloGeneration: {
            type: Number,
        },
        players: {
            type: Array,
        },
        color: {
            type: String,
        },
        step: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    data() {
        return {
            messages: [],
            selectedGeneration: this.generation,
            selectedMessage: undefined,
        };
    },
    components: {
        LogMessageComponent: LogMessageComponent_vue_1.default,
        CardPanel: CardPanel_vue_1.default,
    },
    methods: {
        messageClicked(message) {
            this.selectedMessage = message;
        },
        selectGeneration(gen) {
            if (gen !== this.selectedGeneration) {
                this.getLogsForGeneration(gen);
            }
            this.selectedGeneration = gen;
        },
        getLogsForGeneration(generation) {
            const messages = this.messages;
            if (logRequest !== undefined) {
                logRequest.abort();
                logRequest = undefined;
            }
            const xhr = new XMLHttpRequest();
            logRequest = xhr;
            xhr.open('GET', `${paths_1.paths.API_GAME_LOGS}?id=${this.id}&generation=${generation}`);
            xhr.onerror = () => {
                console.error('error updating messages, unable to reach server');
            };
            xhr.onload = () => {
                if (xhr.status === statusCode_1.statusCode.ok) {
                    messages.splice(0, messages.length);
                    messages.push(...xhr.response);
                    if ((0, PreferencesManager_1.getPreferences)().enable_sounds && window.location.search.includes('experimental=1')) {
                        SoundManager_1.SoundManager.newLog();
                    }
                    if (generation === this.generation) {
                        this.$nextTick(this.scrollToEnd);
                    }
                }
                else {
                    console.error(`error updating messages, response code ${xhr.status}`);
                }
            };
            xhr.responseType = 'json';
            xhr.send();
        },
        scrollToEnd() {
            const scrollablePanel = document.getElementById('logpanel-scrollable');
            if (scrollablePanel !== null) {
                scrollablePanel.scrollTop = scrollablePanel.scrollHeight;
            }
        },
        getClassesGenIndicator(gen) {
            const classes = ['log-gen-indicator'];
            if (gen === this.selectedGeneration) {
                classes.push('log-gen-indicator--selected');
            }
            return classes.join(' ');
        },
        getGenerationsRange() {
            const generations = [];
            for (let i = 1; i <= this.generation; i++) {
                generations.push(i);
            }
            return generations;
        },
        getTitleClasses() {
            const classes = ['log-title'];
            classes.push((0, utils_1.playerColorClass)(this.color, 'shadow'));
            return classes.join(' ');
        },
        lastGenerationClass() {
            return this.lastSoloGeneration === this.generation ? 'last-generation blink-animation' : '';
        },
    },
    mounted() {
        this.getLogsForGeneration(this.generation);
    },
});
