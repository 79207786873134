"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAward = exports.getMilestone = void 0;
const milestonejson = require("@/genfiles/milestones.json");
const awardjson = require("@/genfiles/awards.json");
const milestones = {};
const awards = {};
milestonejson.forEach((e) => {
    milestones[e.name] = e;
});
awardjson.forEach((e) => {
    awards[e.name] = e;
});
function getMilestone(name) {
    return milestones[name];
}
exports.getMilestone = getMilestone;
function getAward(name) {
    return awards[name];
}
exports.getAward = getAward;
